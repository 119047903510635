import React, { ReactNode } from 'react';
import styled  from 'styled-components';
import Header from '../components/Header';
import Footer from '../components/Footer';
import media from '../styles/media';
import 'typeface-lato';
import 'typeface-merriweather';
import '../scss/main.scss';

type PropsType = {
  children: ReactNode;
  id?: string;
  fullWidth?: boolean;
};

export default function Layout({ children, id, fullWidth = false }: PropsType) {
  const content = <Main>{children}</Main>;
  let main = content;
  if (!fullWidth) {
    main = <ContentWrap>{content}</ContentWrap>;
  }

  return (
    <PageWrap id={id}>
      <Header/>
      {main}
      <Footer/>
    </PageWrap>
  );
}

export const ContentWrap = styled.div`
  padding: 4rem 2rem 1rem 2rem;
  margin: auto;

  @media (${media.min.tablet}) {
    padding: 6rem 2rem 4rem 2rem;
  }

  @media (${media.min.laptopM}) {
    max-width: 1000px;
  }
`;

const PageWrap = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
`;
const Main = styled.main`
`;
