import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import HamburgerMenu from 'react-hamburger-menu';
import random from 'random';
import HeaderNavItems from '../constants/header-nav-items';
import media from '../styles/media';
import headerBg1 from '../images/header1.jpg';
import headerBg2 from '../images/header2.jpg';
import { linkColor } from '../styles/mixins';
import { theme } from '../styles/theme';
import AutoLink from './common/AutoLink';
import MenuItem  from './MenuItem';

const bgImages = [headerBg1, headerBg2];
const bgImage = bgImages[random.int(0, bgImages.length - 1)];

export default function Header() {
  const [menuOpen, menuChange] = useState(false);
  const headerRef = useRef<HTMLElement>();

  useEffect(() => {
    setTimeout(() => {
      // Ugly hack to fix Popper not refreshing its positioning when fonts load in
      window.dispatchEvent(new Event('resize'));
    }, 500);
  }, []);

  const data = useStaticQuery<GatsbyTypes.HeaderMetadataQuery>(
    graphql`
      query HeaderMetadata {
        site {
          siteMetadata {
            title
            headerSubtitle
          }
        }
      }
    `
  );

  const { title, headerSubtitle } = data.site.siteMetadata;
  const getHeaderHeight = () => {
    if (headerRef.current) {
      return headerRef.current.clientHeight;
    }
    return 100;
  };

  return (
    <>
      <HeaderWrap menuOpen={menuOpen} bgImage={bgImage} ref={headerRef}>
        <ImageOverlay>
          <HeaderContent menuOpen={menuOpen}>
            <LogoBox>
              <HeaderLink to="/">
                <HeaderTitle>{title}</HeaderTitle>
              </HeaderLink>
              <HeaderLink to="/in-memoriam">
                <HeaderSubtitle>{headerSubtitle}</HeaderSubtitle>
              </HeaderLink>
            </LogoBox>
            <MenuButton
              isOpen={menuOpen}
              menuClicked={() => menuChange(!menuOpen)}
              color="white"
            />
            <Nav menuOpen={menuOpen}>
              <NavList>
                {HeaderNavItems.map(parent => <MenuItem key={parent.title} {...parent} />)}
              </NavList>
            </Nav>
          </HeaderContent>
        </ImageOverlay>
      </HeaderWrap>
      {menuOpen && <HeaderSpacer height={getHeaderHeight()} />}
    </>
  );
}


const HeaderWrap = styled.header<{ menuOpen: boolean; bgImage: string }>`
  //z-index: 3;
  //position: fixed;
  //left: 0;
  //right: 0;
  background-color: #fff;
  color: white;
  background-image: url(${p => p.bgImage});
  background-position: center;
  background-size: cover;
    
  @media (${media.max.tablet}) {
    ${p => p.menuOpen && css`
      position: fixed;
      z-index: 2;
      left: 0;
      right: 0;
  `}
  }
`;
const HeaderSpacer = styled.div<{ height: number }>`
  height: ${p => p.height}px;
`;

const ImageOverlay = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
`;

const HeaderContent = styled.div<{ menuOpen: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-template-columns: 200px auto;
  grid-gap: 1rem;
  padding: 1rem 2rem;
`;

const MenuButton = styled(HamburgerMenu)`
  display: none;
  
  @media (${media.max.tablet}) {
    z-index: 3; 
    display: inline-block;
    font-size: 1.15rem;
  }
`;

const LogoBox = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: center;
`;
const HeaderLink = styled(AutoLink)`
  ${linkColor('white')};
  text-decoration: none;
  transition: ${theme.transitions.medium};

  &:hover {
    ${linkColor(theme.colors.titleHover)}
  }
`;
const HeaderTitle = styled.h1`
  font-size: 24px;
  margin: 0 0 16px;
`;
const HeaderSubtitle = styled.h2`
  margin: 0;
  font-size: 1.5rem;

  @media (${media.max.tablet}) {
    font-size: 1rem;
  }
`;

const Nav = styled.nav<{ menuOpen: boolean }>`
  @media (${media.max.tablet}) {
    position: fixed;
    top: 0;
    left: ${p => p.menuOpen ? 0 : 100}%;
    bottom: 0;
    width: 100%;
    background-color: #111;
    z-index: 2;
    transform: ${p => `translateX(${p.menuOpen ? 0 : 100}%)`};
    transition: ${theme.transitions.medium};
  }
`;

const NavList = styled.ul`
  list-style: none;
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  
  @media (${media.max.tablet}) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;