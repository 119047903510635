import { mapValues } from 'lodash';

const sizes = {
  mobileS: 320,
  mobileM: 375,
  mobileL: 425,
  tablet: 768,
  laptop: 1024,
  laptopM: 1200,
  laptopL: 1440,
  desktop: 2560
};
const min = mapValues(sizes, size => `min-width: ${size}px`);
const max = mapValues(sizes, size => `max-width: ${size - 1}px`);

const media = {
  min,
  max
};
export default media;