import * as React from 'react';
import isAbsoluteUrl from 'is-absolute-url';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components';
import { linkColor } from '../../styles/mixins';

export type PropsType = {
  [key: string]: unknown;
  to: string;
  newTab?: boolean;
  inherit?: boolean;
}

export default function AutoLink({ to, newTab, inherit, ...rest}: PropsType) {
  if (newTab) {
    rest = {
      target: '_blank',
      rel: 'noopener',
      ...rest
    };
  }

  const styles = [
    inherit ? InheritLinkStyle : ''
  ];
  const LinkStyle = styled(Link)`
    ${styles.map(style => css`${style}`)}
  `;
  const AnchorStyle = styled.a`
    ${styles.map(style => css`${style}`)}
  `;

  if (typeof to !== 'string') {
    throw new Error('Invalid link');
  }

  return !isAbsoluteUrl(to)
    ? <LinkStyle to={to} {...rest} />
    : <AnchorStyle href={to} {...rest} />;
}



export const InheritLinkStyle = css`
  ${linkColor('inherit')};
`;
