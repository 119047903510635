import React from 'react';
import { FaFacebook, FaGoodreads } from 'react-icons/fa';

type SocialIcon = {
  icon: JSX.Element;
  url: string;
};

const icons: SocialIcon[] = [
  {
    icon: <FaFacebook />,
    url: 'https://www.facebook.com/pages/Julian-Leatherdale/1551515888451962',
  },
  {
    icon: <FaGoodreads />,
    url: 'https://www.goodreads.com/author/show/13825346.Julian_Leatherdale'
  }
];

export default icons;