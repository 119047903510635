import { parse } from 'url';

export function getDomain(url: string) {
  const parsed = parse(url);
  return parsed.hostname;
}

export function prependSlash(url: string) {
  if (!url.startsWith('/')) {
    return `/${url}`;
  }
  return url;
}
