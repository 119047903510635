import styled from 'styled-components';
import { theme } from './theme';

export const BasicUList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;
export const RowList = styled(BasicUList)`
  display: flex;
`;
export const CenterUList = styled(BasicUList)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const TextButton = styled.button`
  color: black;
  text-decoration: none;
  border: none;
  background: none;
  font-family: ${theme.fonts.serif};
  padding: 0;
  font-size: 1rem;
  display: inline-block;
  line-height: 30px;
  position: relative;

  &-link {
    text-decoration: none;
  }

  &:hover {
    cursor: pointer;
  }
`;
