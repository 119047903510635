const fonts = {
  sansSerif: '"Lato", sans-serif',
  serif: '"Merriweather", serif',
};

const colors = {
  primary: '#3d9991',
  titleHover: '#939393'
};

const transitions = {
  medium: '0.3s'
};

export const theme = {
  fonts,
  colors,
  transitions
};