import { MenuParent } from '../components/MenuItem';

const HeaderNavItems: MenuParent[] = [
  {
    title: 'About',
    slug: 'about-me'
  },
  {
    title: 'Books',
    items: [
      {
        title: 'Death in the Ladies\' Goddess Club',
        slug: 'death-in-the-ladies-goddess-club',
      },
      {
        title: 'The Opal Dragonfly',
        slug: 'the-opal-dragonfly'
      },
      {
        title: 'Palace of Tears',
        slug: 'palace-of-tears'
      },
      {
        title: 'The Phantasmic Detective Agency',
        slug: 'the-phantasmic-detective-agency'
      }
    ]
  },
  {
    title: 'Gallery',
    slug: 'gallery'
  },
  {
    title: 'News',
    slug: 'news'
  },
  {
    title: 'Reviews',
    slug: 'reviews'
  }
];
export default HeaderNavItems;