import React, { forwardRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { FaChevronRight, FaChevronDown } from 'react-icons/fa';
import styled, { css } from 'styled-components';
import { BasicUList } from '../styles/components';
import media from '../styles/media';
import { linkColor } from '../styles/mixins';
import { theme } from '../styles/theme';
import { prependSlash } from '../util/functions';
import AutoLink from './common/AutoLink';

export type MenuChild = {
  title: string;
  slug: string;
}
export type MenuParent = {
  title: string;
  slug?: string;
  items?: MenuChild[];
};


const MenuItem = ({ slug, title, items, isChild = false }: MenuParent & { isChild?: boolean }) => {
  const [itemsOpen, itemsOpenChange] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start'
  });

  const hasChildren = items && items.length;

  return (
    <NavItem isChild={isChild}>
      <FlexSpacer
        onClick={() => itemsOpenChange(prevState => !prevState)}>
        <MenuLink
          ref={setReferenceElement}
          title={title}
          slug={slug}
        />
        {hasChildren && (
          !itemsOpen ? <FaChevronRight /> : <FaChevronDown />
        )}
      </FlexSpacer>
      {hasChildren && (
        <MenuItemDropdown
          style={styles.popper}
          ref={setPopperElement}
          className='dropdown'
          open={itemsOpen}
          {...attributes.popper}
        >
          {items.map(item => (
            <MenuItem key={item.slug} {...item} isChild />
          ))}
        </MenuItemDropdown>
      )}
    </NavItem>
  );
};
export default MenuItem;

function MenuLinkRenderer({ slug, title, onClick }: {
  slug?: string;
  title: string;
  onClick?: () => void;
  popperRef?: React.Ref<HTMLDivElement>;
}, ref) {
  return slug
    ? <StyledLink to={prependSlash(slug)}>{title}</StyledLink>
    : <MenuItemParent ref={ref} onClick={onClick}>{title}</MenuItemParent>;
}
const MenuLink = forwardRef(MenuLinkRenderer);

const NavItem = styled.li<{ isChild: boolean }>`
  ${p => !p.isChild && css`
    margin-left: 2rem;
  `};
  
  font-size: 1.15rem;
  font-family: ${theme.fonts.serif};
  font-weight: 300;
  color: white;
  
  
  @media (${media.min.tablet}) {
    justify-content: flex-end;
    
    &:hover {
      .dropdown {
        display: block;
      }
    }
  }
  @media (${media.max.tablet}) {
    margin: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
const FlexSpacer = styled.div`
  display: flex;
  align-items: center;
  margin: 1em 0;
  cursor: pointer;
  
  > * {
    + * {
      margin-left: 8px;
    }
  }
  
  @media (${media.min.tablet}) {
    svg {
      display: none;
    }
  }
`;

const MenuItemDropdown = styled(BasicUList)<{ open: boolean }>`
  display: flex;
  flex-direction: column;
  background-color: #333;

  @media (${media.min.tablet}) {
    display: none;
    width: auto;
    padding: 8px 24px;
    min-width: 150px;
    z-index: 5;
  }
  @media (${media.max.tablet}) {
    display: ${p => p.open ? 'flex' : 'none'};
    position: static !important;
    transform: none !important;
    align-items: center;
    width: 100%;
  }
`;

const menuItemMixin = css`
  ${linkColor('inherit')};
  text-decoration: none;
`;
const MenuItemParent = styled.div`
  ${menuItemMixin};
`;
const StyledLink = styled(AutoLink)`
  ${menuItemMixin}'
`;