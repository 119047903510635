import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import footerLinks from '../constants/footer-nav';
import socials from '../constants/social-icons';
import { CenterUList, RowList } from '../styles/components';
import { linkColor } from '../styles/mixins';
import AutoLink from './common/AutoLink';
import ListLink from './common/ListLink';

export default function Footer() {
  const showYear = new Date().getFullYear();

  const data = useStaticQuery<GatsbyTypes.FooterMetadataQuery>(
    graphql`
      query FooterMetadata {
        site {
          siteMetadata {
            author
            developer
          }
        }
      }
    `
  );

  const { author, developer } = data.site.siteMetadata;

  return (
    <FooterStyled>
      <SectionLight>
        <Socials>
          {socials.map(({ icon, url }) => {
            return (
              <SocialItem key={url}>
                <SocialLink to={url} newTab>
                  {icon}
                </SocialLink>
              </SocialItem>
            );
          })}
        </Socials>
        <PaddedList>
          {footerLinks.map(link => (
            <ListLink
              key={link.url}
              to={link.url}
              newTab
            >{link.title}</ListLink>
          ))}
        </PaddedList>
      </SectionLight>
      <SectionDark>
        <PaddedList>
          <li>&copy; {author} {showYear}</li>
          <li>Developed by {developer}</li>
        </PaddedList>
      </SectionDark>
    </FooterStyled>
  );
}

const FooterStyled = styled.footer`

`;
const Section = styled.section`
  padding: 32px;
`;
const SectionLight = styled(Section)`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #333;
`;
const SectionDark = styled(Section)`
  background-color: #1f1f1f;
  color: #ccc;
`;
const PaddedList = styled(CenterUList)`
  > * {
    + * {
      margin-top: 8px;
    }
  }
`;
const Socials = styled(RowList)`
  margin-bottom: 8px;
  
  a {
    ${linkColor('white')}
  };
`;
const SocialItem = styled.li`
  font-size: 2rem;
  list-style: none;
  display: inline;
  margin-right: 0.75rem;

  &:last-child {
    margin-right: 0;
  }
`;
const SocialLink = styled(AutoLink)`
  color: white;
`;
